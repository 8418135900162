<template>
    <section>
      <div class="back">
          <i class="el-icon-back" @click="back"></i>
          <span>{{this.$route.query.id}}停放记录</span>
      </div>
      <el-table :data="tableList">
          <el-table-column align="center" label="用户信息" prop="version">
              <template slot-scope="scope">
                  <p>{{ scope.row.name ? scope.row.name : '--' }}</p>
                  <p>{{ scope.row.phone ? scope.row.phone : '--' }}</p>
              </template>
          </el-table-column>
          <el-table-column align="center" label="预约创建时间" prop="appointDatetime"></el-table-column>
          <el-table-column align="center" label="停放时间" prop="createDatetime"></el-table-column>
          <el-table-column align="center" label="离开时间" prop="leaveDatetime"></el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-col :span="24" class="toolbar pagination">
        <el-pagination
          background
          layout=" prev , next , jumper"
          @current-change="handleCurrentChange"
          :page-size="10"
          style="float:right;"
        ></el-pagination>
      </el-col>
    </section>
  </template>
  
  <script>
  export default {
    data() {
      return {
          searchInfo:{ // 筛选条件
              pageNo:1,
              pageSize:10,
              param:{
                  deviceId:'',
                  type:null,
              }
          },
          tableList:[
            {
              name:'lmy',
              phone:'18019220807',
              appointDatetime:'2024-01-05 09:00:00',
              createDatetime:'2024-01-05 11:09:45',
              leaveDatetime:'2024-01-05 18:03:23'
            }
          ], // 表格数据
          communicationOptions:[ // 通讯类型选项
              {value:1,label:'心跳'},
              {value:2,label:'主动上锁'},
          ],
      }
    },
  
    components: {},
  
    computed: {},
  
    mounted() {},
  
    methods: {
      // 返回上一页
      back(){
        this.$router.go(-1)
      },
      // 选择通讯类型
      changeType(e){
  
      },
      // 获取表格数据
      init(){
  
      },
      // 分页操作事件
      handleCurrentChange(val){
        this.searchInfo.pageNo = val;
        this.init();
      }
    },
  };
  </script>
  <style scoped></style>
  